@use "style/constants.scss" as constants;

.emergency-alarm-settings-details-header {
  display: flex;
  flex-direction: column;
  background: constants.$surface2;
  padding: 24px 24px 16px 24px;
  border-radius: 28px 28px 0 0;
  gap: 16px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);

  .header-buttons {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 16px;

    .chip {
      margin-right: auto;
    }
  }

  .title {
    display: flex;
    flex-direction: column;
    gap: 8px;

    .location {
      display: flex;
      align-items: center;
      gap: 8px;

      .MuiSvgIcon-root {
        font-size: 16px;
      }
    }
  }
}
