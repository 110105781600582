@use "style/constants.scss" as constants;

.details-form {
  overflow: hidden;
  height: 100%;

  .details-content {
    all: unset;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 32px;
    width: 100%;
    height: 100%;
    overflow: hidden;

    .left-content {
      width: 256px;
      height: calc(
        100% - 32px
      ); // margin-bottom is not working because of form container height
      border-right: 1px solid rgba(constants.$outline, 0.16);
      padding: 0 32px 0 4px; // 4px to the left so it doesn't clip the dropshadow of the elevated button
      overflow-y: auto;
      box-sizing: border-box;
    }

    .right-content {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      gap: 16px;
      height: 100%;
      max-width: 843px;
    }

    .right-content-container {
      overflow-y: auto;
    }
  }
}
