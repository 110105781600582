@use "style/constants.scss" as constants;

.history-details-left-content-container {
  width: 376px;
  margin: 10px 0px 0px 10px;

  .location {
    display: flex;
    flex-direction: column;
    gap: 16px;

    margin-top: 24px;
  }
}
