@use "style/constants.scss" as constants;

.gateway-filters-container {
  display: flex;
  flex-direction: column;
  gap: 32px;
  width: 100%;

  .top-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .gateway-filters {
      display: flex;
      flex-wrap: wrap;
      gap: 16px;
      align-items: center;

      .clear-filters {
        display: flex;
        align-items: center;
        padding: 0 12px;
        cursor: pointer;
      }
    }

    .refresh-button svg {
      color: constants.$on-surface;
    }
  }

  .monitoring-filters {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 16px;
    width: 100%;
  }
}
