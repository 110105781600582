.date-range-picker-popup-paper {
  width: fit-content;
}

.date-range-picker-input {
  width: 100%;
}

.date-range-picker-popup-container {
  display: flex;
  flex-direction: column;
  gap: 8px;

  .date-range-picker-header-container {
    display: flex;
    flex-direction: row;
    gap: 6px;
    align-items: center;

    svg {
      width: 14px;
      height: 14px;
    }
  }

  .date-range-picker-container {
    display: flex;
    flex-direction: row;
    gap: 16px;
  }

  .date-range-picker-actions-container {
    display: flex;
    align-self: end;
    gap: 24px;
  }

  .date-range-picker-actions-container .button-center-text {
    padding-right: 16px;
  }
}
