.device-details-popup-settings-container {
  .client-info-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 8px;

    .client-info-item {
      flex-grow: 0;
      width: fit-content;
      display: flex;
      flex-direction: column;
      gap: 16px;
    }

    .full-width {
      flex-grow: 1;
    }

    .client-secret-container {
      display: flex;
      gap: 16px;
      align-items: center;
    }

    .information-part-container-header {
      display: flex;
      flex-direction: row;
      gap: 16px;
      padding-bottom: 16px;
      align-items: center;
      width: 100%;
    }

    .information-part-container-header.padding-top-only {
      padding-top: 16px;
    }

    .credentials {
      width: 360px;
    }
  }

  input::-ms-reveal {
    display: none;
  }
}
