@use "style/constants.scss" as constants;

.headerContainer {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  width: 100%;
  align-items: center;

  > :nth-child(1) {
    grid-column: 1;
  }

  .newLabel {
    grid-column: 2;
    justify-self: center;
    display: flex;
    height: 24px;
    padding: 8px;
    align-items: center;
    border-radius: 8px;
    color: constants.$on-secondary;
    background: var(--Darkpurple, #2a0059);
    border: 1px solid var(--Rebranding-sys-light-outline, #7b757f);
  }

  .editButton {
    grid-column: 3;
    justify-self: end;
    margin: 0 24px;
    padding: 10px 12px;
    min-width: fit-content;
  }

  .disabledIcon {
    opacity: 0.38;
  }
}
