@use "style/constants.scss" as constants;

.history-filters {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;

  .clear-filters {
    display: flex;
    align-items: center;
    padding: 0 12px;
    cursor: pointer;
  }
}
