@use "style/constants.scss" as constants;

.malfunction-notifications-content {
  display: flex;
  flex-direction: column;
  margin: 32px 0;
  overflow: auto;

  .save-settings {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .settings {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    margin: 32px 0;

    > p {
      flex-grow: 1;
    }

    form {
      display: flex;
      flex-direction: column;
      width: 100%;

      .no-recipients {
        display: flex;
        justify-content: center;
        margin: 16px 0;
      }

      .row {
        display: flex;
        flex: 1;
        gap: 16px;
        margin: 24px 0;
        align-items: flex-start;

        .remove-button {
          margin-top: 6px;
          color: constants.$primary;
        }

        > *:not(.remove-button) {
          flex: 1;
        }
      }
    }
  }
}
