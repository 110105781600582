@use "style/constants.scss" as constants;

.bread-crumb-container {
  display: flex;
  gap: 4px;
  align-items: center;

  .start-icon-container {
    margin-right: 12px;

    svg {
      color: constants.$primary;
      height: 24px;
      width: 24px;
    }
  }

  .chevron-right-icon {
    color: constants.$on-surface-variant;
    height: 16px;
    width: 16px;
  }

  * {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.tooltip {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  .tooltip-typography {
    color: constants.$inverse-on-surface;
  }

  .chevron-right-icon {
    color: constants.$inverse-on-surface;
    height: 16px;
    width: 16px;
  }
}
