@use "style/constants.scss" as constants;

.linkable-devices-table {
  th {
    background-color: constants.$table-header;
  }

  .filter-bar {
    display: flex;
    gap: 16px;
  }
}
