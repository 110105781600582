.dashboard-container {
  height: 100%;
  display: flex;
  flex-direction: column;

  .dashboard-child-container {
    flex: 1;
    overflow: hidden;
  }
}
