@use "style/constants.scss" as constants;
.sliderColumn {
  display: flex;
  flex-direction: column;
  gap: 16px;

  .sliderRow {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 16px;
  }
}
