@use "style/constants.scss" as constants;

$secondary-menu-width: 232px;

.secondary-menu {
  cursor: pointer;
  position: fixed;
  bottom: 12px;
  left: 12px;
  right: 12px;
  padding: 16px;
  width: $secondary-menu-width;
  background: constants.$secondary-container;
  border-radius: 28px;

  .tenant {
    display: -webkit-box;
    flex: 1;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    word-break: break-word;
    overflow: hidden;
    text-align: left;
  }

  .icon-color {
    color: constants.$on-surface-variant;
  }

  .MuiTypography-root {
    color: constants.$on-surface;
  }
}

.secondary-menu-opened {
  position: absolute;
  bottom: 12px;
  left: 12px;
  right: 12px;
  padding: 16px;
  background: constants.$surface;
  border-radius: 28px;
  width: $secondary-menu-width;
  overflow: hidden;
  box-shadow: constants.$elevation-light-1;

  .menu-item {
    cursor: pointer;
  }

  .MuiTypography-root {
    color: constants.$on-surface;
  }

  .logout {
    cursor: pointer;
    color: constants.$error;
  }

  @keyframes slidein {
    from {
      max-height: 0;
    }

    to {
      max-height: 100%;
    }
  }

  @keyframes slideout {
    from {
      max-height: 100%;
    }

    to {
      max-height: 0;
    }
  }
}
