@use "style/constants.scss" as constants;

.temporary-users-container {
  height: 100%;
  display: flex;
  flex-direction: column;

  .temporary-access-filter-component-container {
    display: flex;
    gap: 16px;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
  }

  .avatar-container {
    display: flex;
    width: 32px;
    height: 32px;
    align-items: center;
    justify-content: center;
    border-radius: 78px;
    background: constants.$lightOrange;
    color: constants.$on-surface-variant;
    border: 1px solid constants.$outline;
  }
}
