.external-link-container {
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
  cursor: pointer;
  width: fit-content;

  .linkt-text {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }
}
