.resident-devices-list-container {
  gap: 16px;

  .link-devices-button {
    margin-top: 16px;
    float: right;
  }

  .resident-details-devices-container {
    display: flex;
    flex-direction: column;

    .device-container {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      min-height: 44px;
      gap: 16px;

      .unlinked-device {
        text-decoration: line-through;
      }

      .device-name {
        margin-right: auto;
      }

      .device-icon {
        margin-right: 8px;
      }
    }
  }
}
