.container {
  display: flex;
  flex-direction: column;
  gap: 32px;

  &.viewing {
    gap: 16px;
  }

  .textfield {
    max-width: 256px;
  }
}
