@use "style/constants.scss" as constants;

.device-details-popup-settings-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  gap: 32px;
  &.viewing {
    gap: 40px;
  }

  .settings-item {
    max-width: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 16px;

    .header-row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 100%;
    }

    .settings-item-content {
      display: flex;
      flex-direction: column;
      gap: 16px;
      max-width: 100%;
      margin-bottom: 25px;
    }
  }

  .icon-text {
    display: flex;
    flex-direction: row;
    gap: 16px;
    height: 38px;
  }

  hr {
    width: 100%;
  }

  .select-field {
    width: 245px;
  }

  .full-width {
    width: 100%;
  }
}
