@use "style/constants.scss" as constants;

.bread-crumb-parent-container {
  .bread-crumb-container {
    padding-top: 10px;
  }
  .bread-crumb-container:first-child {
    padding-top: 0px;
  }
}
