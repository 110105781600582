@use "style/constants.scss" as constants;

.menu {
  min-width: 256px;

  .logo-container {
    align-self: center;
    margin: 36px 45px 24px 45px;
    height: 80px;
  }

  .menu-items-container {
    height: calc(100vh - 275px);
    overflow-y: auto;
  }

  .MuiPaper-root {
    border: none !important;
  }

  .MuiDrawer-paper {
    width: 256px;
    background: constants.$surface1;

    padding: 0 12px 0 12px;
  }

  .MuiListItem-root {
    .Mui-selected,
    .Mui-selected:hover {
      background-color: constants.$secondary-container;

      .MuiSvgIcon-root {
        color: constants.$on-secondary-container;
      }

      span {
        color: constants.$on-secondary-container;
      }
    }

    span {
      color: constants.$on-surface-variant;
    }

    .MuiSvgIcon-root {
      font-size: 24px;
      color: constants.$on-surface-variant;
    }

    .MuiButtonBase-root {
      height: 56px;
      border-radius: 54px;

      .MuiListItemIcon-root {
        min-width: 24px;
        margin-right: 14px;
      }
    }
  }

  .menu-divider {
    margin: 8px 16px;
    border-color: constants.$outline;
    opacity: 0.16;
  }
}
