@use "style/constants.scss" as constants;

.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  padding-bottom: 32px;

  &.viewing {
    gap: 40px;
  }

  .settingsItem {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    width: 100%;

    .settingsItemContent {
      display: flex;
      flex-direction: column;
      gap: 16px;
    }
  }

  hr {
    width: 100%;
  }
}
