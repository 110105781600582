.configurationContainer {
  width: 100%;
  overflow-x: hidden;

  .localBusUnitsContainer {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
}
