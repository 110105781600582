@use "style/constants.scss" as constants;

.configuration-row-container {
  display: flex;
  flex-direction: row;
  padding: 16px 0px;
  align-items: center;
  width: 100%;

  .configuration-row-column {
    display: flex;
    flex-direction: column;
    width: 100%;

    .configuration-row-in-column {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      padding-right: 16px;
    }
  }

  .switch {
    transform: translateX(-12px);
    margin-left: 16px;
  }

  &:not(.scenario-active) {
    .scenario-title {
      color: constants.$on-surface-variant;
      opacity: 0.6499999761581421;
    }
  }

  &:not(.scenario-active),
  &:not(.alarm-active),
  &:not(.is-in-timeframe) {
    .grey-when-inactive {
      opacity: 0.6499999761581421;
    }
  }
}

.configuration-row-container > :not(:nth-child(-n + 2)) {
  margin-left: 16px;
}
