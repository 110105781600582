@use "style/constants.scss" as constants;

.history-details-header {
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-grow: 0;
  gap: 16px;
  align-items: center;
  padding-left: 10px;

  .buttons {
    display: flex;
    flex-direction: row;
    gap: 16px;

    .close-button {
      width: 40px;
      height: 40px;
      margin-left: 8px;
      background-color: constants.$primary-container;

      svg {
        color: constants.$on-primary-container;
        height: 20px;
        width: 20px;
      }
    }

    .refresh-button {
      svg {
        color: constants.$on-surface;
        height: 24px;
        width: 24px;
      }
    }
  }
}
