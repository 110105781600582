@use "style/constants.scss" as constants;

.alert {
  flex: 0 1 calc(50% - 12px);
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  border-radius: 16px;
  height: fit-content;

  &.info {
    background-color: constants.$alert-info;

    .text {
      color: constants.$on-secondary-container;
    }
  }

  &.success {
    background-color: constants.$alert-success;

    .text {
      color: constants.$on-surface;
    }
  }

  &.warning {
    background-color: constants.$alert-info;

    .text {
      color: constants.$on-surface;
    }
  }

  &.error {
    background-color: constants.$alert-error;
    border: 3px solid
      var(--Rebranding-sys-light-error, constants.$alert-error-border);

    .text {
      color: constants.$on-error-container;
    }
  }

  &.none {
    background-color: constants.$surface-variant;

    .text {
      color: constants.$on-surface-variant;
    }
  }

  .body {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
}
