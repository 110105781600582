@use "style/constants.scss" as constants;

.historyContainer {
  height: 100%;
  display: flex;
  flex-direction: column;

  .historyFilterComponentContainer {
    display: flex;
    gap: 16px;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
  }

  .refreshButton svg {
    color: constants.$on-surface;
  }

  .alarmType {
    display: flex;
    flex-direction: column;
  }

  .status {
    border-radius: 8px;
    padding: 4px 8px;
    margin: 8px 0;
    max-width: fit-content;

    .statusText {
      color: constants.$on-primary;
    }
  }

  .backgroundColorReported {
    background-color: transparent;
    border: 1px solid constants.$outline;
    .statusText {
      color: unset;
    }
  }

  .backgroundColorAccepted {
    background-color: constants.$outline;
  }

  .isDiverted {
    border-radius: 8px;
    padding: 4px 8px;
    max-width: fit-content;
    background-color: constants.$secondary-container;

    .categoryText {
      color: constants.$on-secondary-container;
    }
  }

  .breadCrumbContainer {
    padding: 8px 0px;
  }

  .breadCrumbItem {
    color: constants.$on-surface-variant;
  }
}
