@use "style/constants.scss" as constants;

.device-filters-container {
  display: flex;
  flex-direction: column;
  gap: 32px;

  .top-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .device-filters {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 16px;
      flex-wrap: wrap;

      .clear-filters {
        display: flex;
        align-items: center;
        padding: 0 12px;
        cursor: pointer;
      }
    }

    .refresh-button svg {
      color: constants.$on-surface;
    }
  }

  .monitoring-filters {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 16px;
  }
}
