@use "style/constants.scss" as constants;

.resident-details {
  max-height: 100%;
  display: flex;
  flex-direction: column;

  form {
    max-height: 100%;
    min-height: 0;
    display: flex;
    flex-direction: column;
  }

  .body {
    overflow-y: auto;
    overflow-x: hidden;
    border-radius: 0 0 28px 28px;
    padding: 16px 24px 24px 24px;
    margin-bottom: 32px;
    background: constants.$surface1;
  }

  .MuiFormControl-root {
    min-width: 100%;
  }

  .information {
    display: flex;
    flex-direction: column;
    gap: 8px;

    .noteContainer {
      padding: 16px;
      border-radius: 16px;
      background-color: constants.$alert-info;
    }
  }

  .noScenarios {
    color: constants.$on-surface-variant;
  }

  .accordion-summary-content {
    display: flex;
    flex-direction: row;
    gap: 16px;
    padding: 16px 0px;
    align-items: center;
    width: 100%;
  }
}
