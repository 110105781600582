.container {
  display: flex;
  flex-direction: column;

  .scenario {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 44px;
    flex: 1;

    .row {
      display: flex;
      align-items: center;
      gap: 16px;
    }

    .unlinked {
      text-decoration: line-through;
    }
  }
}
