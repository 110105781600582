@use "style/constants.scss" as constants;

.page-tab-container {
  display: flex;
  justify-content: flex-end;

  .button-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 8px;
    color: constants.$on-background;

    .icon {
      width: 24px;
      height: 24px;
      font-size: 16px;
    }
  }
}
