@use "style/constants.scss" as constants;

.timeline-component-container {
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  margin-bottom: 8px;

  .timeline-item-row {
    display: flex;
    flex-direction: row;
    padding: 0 24px;
    border-radius: 16px;
    align-items: stretch;

    &.highlighted {
      background-color: constants.$secondary-container;
    }

    .information-column {
      display: flex;
      flex-direction: column;
      gap: 4px;
      padding: 8px 16px;
      width: 100%;

      &.highlighted {
        justify-content: center;
      }

      &.first-item {
        padding: 0px 16px 8px 16px;
      }

      &.time-column {
        align-items: end;
      }
    }
  }

  .open-chip {
    margin-left: 24px;
    border: 1px solid constants.$outline;
    border-radius: 8px;
    padding: 4px 8px;
    width: fit-content;
  }
}
