@use "style/constants.scss" as constants;

.tenant-access-indicator {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 44px;
  z-index: 9999;

  background: #ff5e00;
  color: white;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding: 0 24px;

  .tenant-header {
    padding: 9px 0;
    height: fit-content;
    display: flex;
    margin: auto;
    flex-direction: row;
    align-items: center;

    p,
    h5 {
      color: white;
      text-align: center;
    }
  }

  button {
    color: white;
  }
}
