@use "style/constants.scss" as constants;

.send-test-event-popup {
  display: flex;
  flex-direction: column;
  gap: 16px;
  min-width: 320px;

  .send-test-event-header {
    display: flex;
    width: 100%;
    flex-grow: 0;
    gap: 16px;
    justify-content: space-between;

    .close-button {
      width: 40px;
      height: 40px;
      margin-left: 8px;
      background-color: constants.$primary-container;
      justify-self: flex-end;

      svg {
        color: constants.$on-primary-container;
        height: 20px;
        width: 20px;
      }
    }
  }

  .send-test-event-form-fields {
    all: unset;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    width: 100%;
    height: 100%;
    overflow: hidden;
    padding-top: 16px;
    padding-bottom: 24px;

    input,
    .input-field {
      width: 320px;
    }
  }
}
