@use "style/constants.scss" as constants;

.alarm-card-container {
  border-radius: 16px;
  border-left: 6px solid;
  background-color: constants.$background;
  box-shadow: constants.$elevation-light-3;

  &.highestPriority {
    border-color: #ba1a1a;

    &.status-Accepted {
      border-color: rgba(#ba1a1a, 0.5);
    }
  }

  &.highPriority {
    border-color: #ffa014;

    &.status-Accepted {
      border-color: rgba(#ffa014, 0.5);
    }
  }

  &.mediumPriority {
    border-color: #f3d94e;

    &.status-Accepted {
      border-color: rgba(#f3d94e, 0.5);
    }
  }

  &.defaultPriority {
    border-color: #f6f6f6;

    &.status-Accepted {
      border-color: rgba(#f6f6f6, 0.5);
    }
  }

  &.status-Accepted {
    background-color: rgba(constants.$background, 0.5);
  }

  &.status-Handled {
    background-color: constants.$surface-variant;
  }

  &.status-Cancelled {
    background-color: constants.$surface-variant;
  }

  .diverted-tag {
    background-color: constants.$secondary-container;
    padding: 4px 8px;
    border-radius: 8px 0;
    width: fit-content;
    margin-bottom: -8px;

    &.status-Accepted {
      opacity: 50%;
    }
  }

  .alarm-card-content {
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 12px;

    .content-names-row {
      display: flex;
      flex-direction: row;
      gap: 16px;

      &.status-Accepted {
        opacity: 50%;
      }

      .notification-icon-background {
        display: flex;
        min-width: 40px;
        min-height: 40px;
        max-width: 40px;
        max-height: 40px;
        background: constants.$surface4;
        align-items: center;
        justify-content: center;
        border-radius: 78px;
        color: constants.$on-surface;

        svg {
          color: constants.$on-surface;
          height: 24px;
          width: 24px;
        }
      }

      .content-names {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
    }

    .content-information-row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      gap: 8px;

      &.status-Accepted {
        opacity: 50%;
      }
    }

    .content-nearby-information-row {
      display: flex;
      flex-direction: row;
      gap: 4px;

      &.open-google-maps {
        gap: 4px;
        cursor: pointer;
        text-decoration: underline;
      }

      &.status-Accepted {
        opacity: 50%;
      }
    }

    .accepted-by-users-container {
      display: flex;
      flex-wrap: wrap;
      gap: 8px;
    }

    .status-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      border: 1px solid constants.$outline;
      border-radius: 24px;
      width: fit-content;
      padding: 4px;

      .avatar-container {
        display: flex;
        min-width: 24px;
        min-height: 24px;
        max-width: 24px;
        max-height: 24px;
        align-items: center;
        justify-content: center;
        border-radius: 20px;
        background: constants.$lightOrange;
        color: constants.$on-surface-variant;
        border: 1px solid constants.$outline;
      }

      .name {
        padding: 4px;
      }
    }
  }
}
