@use "style/constants.scss" as constants;

.time-frames-container {
  .time-frames-row {
    margin-top: 16px;
    width: 100%;
    display: flex;
    flex-direction: column;

    .all-day-time-frame {
      .MuiFormControl-root {
        min-width: 0;
        display: flex;
        flex-direction: row;
        align-items: center;
      }
    }

    .time-pickers {
      margin-top: 16px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      gap: 16px;

      .delete-icon {
        margin-top: auto;
        margin-bottom: auto;
        width: 30px;
        height: 30px;
        color: constants.$primary;
        cursor: pointer;
      }

      .validationError {
        color: constants.$error;

        .MuiFormLabel-root {
          color: constants.$error;
        }

        .MuiInputBase-root {
          color: constants.$error;
        }

        .MuiIconButton-root {
          color: constants.$error;
        }

        .MuiOutlinedInput-notchedOutline {
          border-color: constants.$error;
        }
      }
    }

    .validationText {
      margin: 4px 16px 0px 16px;
      .typographyColor {
        color: constants.$error;
      }
    }

    .add-time-frame {
      color: constants.$primary;
      cursor: pointer;
      display: flex;
      flex-direction: row;
      align-self: flex-start;
      align-items: center;
      gap: 11px;
      margin: 16px 0 16px 12px;

      p {
        color: constants.$primary;
      }
    }
  }

  .time-picker {
    min-width: 0;
    width: 100%;
  }
}

.time-picker-popper {
  .MuiList-root {
    width: 75px;
    overflow-y: visible;

    &::after {
      height: 10px;
    }
  }

  .MuiMultiSectionDigitalClock-root .MuiMenuItem-root {
    padding: 4px;
    border-radius: 0;
    min-width: 0;
    margin: 0;
    margin-left: auto;
    margin-right: auto;
  }
}
