@use "style/constants.scss" as constants;

.temporary-users-status {
  display: flex;
  margin: 8px 0px;
  padding: 4px 8px;
  align-items: center;
  border-radius: 8px;
  border: 1px solid constants.$outline;
  width: fit-content;
  font-size: 11px;
}

.active {
  background-color: constants.$success;
  color: constants.$on-primary;
}
