@use "style/constants.scss" as constants;

.resident-row-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 0;

  .resident-status {
    display: flex;
    min-width: 40px;
    min-height: 40px;
    max-width: 40px;
    max-height: 40px;
    align-items: center;
    justify-content: center;
    border-radius: 78px;
    background: constants.$surface3;
    color: constants.$on-surface;
  }

  .resident-information {
    display: flex;
    flex-direction: column;
    margin-left: 24px;

    .resident-of {
      color: constants.$on-surface-variant;
    }

    .resident-name {
      color: constants.$on-surface;
    }

    .selected {
      color: constants.$on-secondary-container;
    }

    .bread-crumb-item {
      color: constants.$on-surface-variant;
    }
  }
}
