.container {
  margin-bottom: 16px;

  .contentContainer {
    padding: 0 16px;

    .row {
      padding: 8px 0;
      display: flex;
      justify-content: space-between;
      width: 100%;
      align-items: center;

      .unlinkButton {
        margin: 0 24px;
        padding: 0px 12px;
        min-width: fit-content;
      }

      .disabledIcon {
        opacity: 0.38;
      }
    }

    .clickable {
      cursor: pointer;
      text-decoration: underline;
      text-underline-position: under;
    }
  }

  .divider {
    padding: 8px 0px;
  }
}
