@use "style/constants.scss" as constants;

.line-with-circle {
  display: flex;
  flex-direction: column;

  .line {
    width: 2px;
    background-color: constants.$primary;
    flex: 1;
    margin: 0 auto;

    &.first-or-last-item {
      width: 0px;
    }

    &.fixed {
      min-height: 8px;
      max-height: 8px;
    }
  }

  .circle {
    width: 16px;
    height: 16px;
    background-color: constants.$brightPurple;
    border-radius: 50%;
  }
}
