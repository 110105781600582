.role-permissions-tabs {
  margin-top: 24px;
}

.role-permissions {
  padding-top: 64px;
  margin-bottom: 28px;
  overflow-y: auto;

  .role-permission-group-divider {
    margin-top: 28px;
    margin-bottom: 28px;
  }
}
