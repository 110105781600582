@use "style/constants.scss" as constants;

.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-bottom: 32px;

  .settingsItem {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    width: 100%;

    .settingsItemContent {
      display: flex;
      flex-direction: column;
      gap: 16px;
    }
  }

  .divider {
    padding-top: 40px;
  }
}
