@use "style/constants.scss" as constants;

.monitoring-item {
  display: flex;
  flex: 1 0 200px;
  flex-direction: column;
  justify-content: space-between;
  padding: 16px;
  border-radius: 8px;
  border: 1px solid constants.$outline-variant;
  height: 128px;

  .title {
    display: flex;
    align-items: center;
    gap: 8px;
  }
}
