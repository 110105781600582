@use "style/constants.scss" as constants;

.emergency-alarm-settings-container {
  display: flex;
  overflow: hidden;
  justify-content: space-between;
  margin: 32px 0;

  .left-content {
    display: flex;
    flex-direction: column;
    flex: 1;
    gap: 24px;
  }
}
