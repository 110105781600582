@use "style/constants.scss" as constants;

.heading {
  th {
    background-color: constants.$table-header;
  }

  tr {
    cursor: pointer;
  }
}
