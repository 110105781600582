@use "style/constants.scss" as constants;

.role-details-popup {
  .role-details-popup-paper {
    padding-bottom: 0;
    height: 960px;
    width: 1227px;
    max-width: 1227px;
  }
}
