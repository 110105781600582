.container {
  width: 100%;

  .configurationRow {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    &:last-child {
      padding-bottom: 0;
    }
  }

  .explanationRow {
    padding: 8px 12px 16px 0px;
  }
}
