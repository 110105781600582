@use "style/constants.scss" as constants;

.popup {
  .popupPaper {
    padding-bottom: 0;
    height: 960px;
    width: 1227px;
    max-width: 1227px;
    display: flex;
    flex-direction: column;

    .detailsForm {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      gap: 16px;
      height: 100%;

      .fieldset {
        overflow-y: auto;
        border: none;
      }
    }
  }
}
