@use "style/constants.scss" as constants;

.acousticScenarioConfigurationContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  border: 1px solid constants.$outline-variant;
  border-radius: 8px;
  padding: 16px;

  .alarmActiveRow {
    margin-top: 16px;

    .alarmActiveSelect {
      width: 100%;
      height: 56px;
    }
  }

  .restoreRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .restoreButton {
      color: constants.$primary;
      cursor: pointer;
    }
  }

  .alarmRow {
    margin-top: 16px;

    .sendAlarmSelect {
      width: 100%;
      height: 56px;
    }
  }

  .acousticSettings {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-top: 16px;

    .sliderIcon {
      margin-top: 4px;
    }
    .sliderIconSmall {
      margin-top: 8px;
      width: 14px;
      height: 14px;
    }
  }

  .temporaryConfigurationEnabledControl {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}
