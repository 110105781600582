@use "style/constants.scss" as constants;

.modal {
  .locationsModalPaper {
    padding-bottom: 0;
    height: 960px;
    width: 960px;
    max-width: 960px;
  }

  .container {
    display: flex;
    flex-direction: column;
    gap: 24px;
    max-height: 100%;

    .heading {
      th {
        background-color: constants.$table-header;
      }
    }

    .headerContainer {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 8px;
      align-self: stretch;
      flex-grow: 0;

      .headerTitle {
        flex-grow: 1;
      }

      .headerActionsContainer {
        display: flex;
        flex-direction: row;
        gap: 40px;

        .linkButton {
          .checkIcon {
            font-size: 16px;
          }
        }
      }
    }

    .searchBarContainer {
      display: flex;
      flex-direction: row;
    }
  }

  .closeButton {
    width: 40px;
    height: 40px;
    background-color: constants.$primary-container;

    svg {
      color: constants.$on-primary-container;
      height: 20px;
      width: 20px;
    }
  }
}
