@use "style/constants.scss" as constants;

.modal {
  .container {
    display: flex;
    flex-direction: column;
    gap: 24px;
    max-height: 100%;

    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 8px;

      .actions {
        display: flex;
        flex-direction: row;
        gap: 40px;
      }
    }
  }

  .linkButton {
    svg {
      font-size: 16px;
    }
  }

  .closeButton {
    width: 40px;
    height: 40px;
    background-color: constants.$primary-container;

    svg {
      color: constants.$on-primary-container;
      height: 20px;
      width: 20px;
    }
  }
}
