@use "style/constants.scss" as constants;

.user-information-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 16px;
  height: 48px;

  .MuiTypography-root {
    color: constants.$on-surface;
  }

  .avatar-container {
    display: flex;
    min-width: 48px;
    min-height: 48px;
    align-items: center;
    justify-content: center;
    border-radius: 78px;
    background: constants.$lightOrange;
    color: constants.$on-surface-variant;
    border: 1px solid constants.$outline;
  }

  .name-container {
    min-width: 0;

    .name {
      overflow-wrap: break-word;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }
}
