@use "style/constants.scss" as constants;

.tenant-monitoring-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 40px;
}

.tenant-monitoring-items {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}
