@use "style/constants.scss" as constants;

.filter-chip-date {
  .options {
    width: 500px;
    z-index: 100;
    position: absolute;
    background: constants.$surface1;
    padding: 24px;

    &.hidden {
      visibility: hidden;
    }

    .date-time-picker-row {
      display: flex;
      gap: 24px;

      .MuiInputBase-input {
        cursor: pointer;
      }
    }

    .buttons-row {
      display: flex;
      justify-content: flex-end;
      gap: 24px;
      padding-top: 24px;
    }
  }

  .active {
    background: constants.$secondary-container;
  }

  .time-picker-popper {
    .MuiList-root {
      width: 75px;
      overflow-y: visible;

      &::after {
        height: 10px;
      }
    }

    .MuiMultiSectionDigitalClock-root .MuiMenuItem-root {
      padding: 4px;
      border-radius: 0;
      min-width: 0;
      margin: 0;
      margin-left: auto;
      margin-right: auto;
    }
  }
}
