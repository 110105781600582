@use "style/constants.scss" as constants;

.emergency-alarm-settings-details {
  display: flex;
  width: 0;
  background: constants.$surface;
  border-radius: 28px;
  overflow: hidden;
  transition: width 0.15s ease;

  &.is-open {
    display: flex;
    width: 480px;
    margin-left: 32px;

    form {
      display: flex;
      flex-direction: column;
      min-width: 480px;
      height: 100%;
    }
  }

  form {
    display: none;
  }

  .emergency-alarm-settings-details-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 100%;
    overflow-y: auto;
    gap: 16px;
    padding: 24px;

    .explanation-content {
      background-color: constants.$surface-variant;
      display: flex;
      flex-direction: column;
      gap: 8px;
      padding: 16px;
      margin-bottom: 16px;
      border-radius: 8px;
      white-space: pre-line;
    }

    .input-field {
      width: 100%;
    }
  }
}
