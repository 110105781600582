@use "style/constants.scss" as constants;

.monitoring-filter-container {
  justify-content: space-between;
  padding: 16px;
  border-radius: 8px;
  border: 1px solid constants.$outline-variant;
  height: 128px;
  cursor: pointer;

  &.selected {
    background-color: constants.$secondary-container;
  }

  .text-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
  }

  .max-lines {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    line-clamp: 3;
    -webkit-box-orient: vertical;
  }
}
