@use "style/constants.scss" as constants;

.chip {
  display: inline-flex;
  padding: 8px;
  background: constants.$background;
  border: 1px solid constants.$outline;
  border-radius: 8px;
  margin: 8px 0;

  &.is-custom {
    background: constants.$secondary;

    p {
      color: constants.$on-secondary;
    }
  }
}
