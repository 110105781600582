@use "style/constants.scss" as constants;

.residents-settings-container {
  display: flex;
  height: 100%;
  overflow-y: hidden;
  gap: 32px;

  .residents-settings-structure-container {
    width: 100%;
    display: flex;
    flex-direction: column;

    &.detail-is-opened {
      width: 60%;
      min-width: 480px;
      max-width: 980px;
    }
  }

  .devices {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;

    .device-icon {
      font-size: 16px;
    }
  }

  .resident-detail-container {
    width: 0%;
    display: none;

    &.detail-is-opened {
      display: block;
      flex-grow: 1;
      animation: reveal 150ms forwards;
    }
  }
}

.selected-residents-container {
  background-color: constants.$secondary-container;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 8px;
  padding-bottom: 8px;
  border-radius: 56px;

  .selected-residents-label {
    color: constants.$on-surface;
  }
}

.resident-header-container {
  padding-left: 64px;
}

@keyframes reveal {
  from {
    clip-path: inset(0 0 0 100%);
  }

  to {
    clip-path: inset(0 0 0 0);
  }
}
