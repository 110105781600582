.device-bread-crumb-container {
  margin-top: 2px;
  width: fit-content;
  max-width: 100%;
}

.system-settings-link-organisation-buttons {
  display: flex;
  flex-direction: row;
  flex-grow: 0;
  align-items: flex-start;
  padding: 0px;
  gap: 16px;
}
