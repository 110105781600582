@use "style/constants.scss" as constants;

.tenant-details-information-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 40px;

  .tenant-title {
    word-break: break-word;
  }

  .information-part-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;

    button:first-of-type {
      margin: 0 0 32px 0;
    }

    .form-field {
      width: 100%;
    }
  }
}
