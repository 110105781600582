@use "style/constants.scss" as constants;

.monitoring-bar {
  display: flex;
  height: 24px;
  min-width: 200px;
  border-radius: 8px;
  overflow: hidden;
  background-color: #ffffff;
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.45);
  justify-content: center;
  align-items: center;

  > div {
    flex: 1;
    height: 100%;
  }

  .red {
    background: rgba(186, 26, 26, 0.5);
  }

  .orange {
    background: rgba(255, 94, 0, 0.5);
  }

  .green {
    background: rgba(59, 220, 104, 0.5);
  }

  .grey {
    background: rgba(171, 171, 171, 0.5);
  }
}
