.embedded-report-container {
  width: 100%;
  height: 100%;

  .embedded-report {
    width: 100%;
    height: 100%;

    iframe {
      border: none;
    }
  }
}
