.dividerContainer {
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
  width: 100%;

  hr {
    flex-grow: 1;
  }

  .startText {
    white-space: nowrap;
  }
}
