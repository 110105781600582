@use "style/constants.scss" as constants;

.container {
  height: 100%;
  display: flex;
  flex-direction: column;

  .filterComponentContainer {
    display: flex;
    gap: 16px;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
  }

  .listOrganisationBreadCrumb {
    width: 400px;
    max-width: 400px;
  }

  .iconRow {
    display: flex;
    flex-direction: row;
    gap: 16px;
    margin-right: 8px;
  }
}
