@use "style/constants.scss" as constants;

.multi-value-filter-chip {
  .options {
    width: fit-content;
    z-index: 100;
    position: absolute;
    background: constants.$surface1;
    padding: 0px 8px;
    overflow-y: auto;
    max-height: 60vh;

    &.hidden {
      visibility: hidden;
    }
  }

  .no-filter-options {
    padding: 0px 12px;
    margin: 9px 0px;
  }

  .active {
    background: constants.$secondary-container;
  }
}
