@use "style/constants.scss" as constants;

.titleButtonsContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 8px;

  .buttonContainer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 16px;

    .closeButton {
      width: 40px;
      height: 40px;
      margin: 0 8px;
      background-color: constants.$primary-container;

      svg {
        color: constants.$on-primary-container;
        height: 20px;
        width: 20px;
      }
    }
  }
}
