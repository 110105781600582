.buttonsConfigurationContainer {
  .contentContainer {
    padding: 20px 16px;

    .switchRow {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-content: center;
      justify-content: space-between;

      .switchRowText {
        align-self: center;
      }
    }
  }

  .divider {
    padding: 16px 0px;
  }
}
