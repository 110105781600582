.app-information-container {
  .company-container {
    padding-top: 24px;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .privacy-and-terms-container {
    padding-top: 24px;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
}
