@use "style/constants.scss" as constants;

.role-information {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  gap: 32px;
  &.viewing {
    gap: 40px;
  }

  .role-name-title {
    margin-top: 56px;
    overflow-wrap: break-word;
  }

  .idp-remark {
    margin-top: -16px;
  }

  .information-item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 8px;
    width: 100%;
  }

  .information-item-textfield {
    width: 100%;
  }

  .assignable-to-temporary-user-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
}
