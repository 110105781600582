@use "style/constants.scss" as constants;

.temporary-user-details-popup-paper {
  width: 716px;
}

.temporary-user-details-container {
  display: flex;
  flex-direction: column;

  .loading-indicator {
    width: 100%;
    height: 736px;
    display: flex;
    justify-content: center;
  }

  .temporary-user-details-header-divider {
    padding-bottom: 16px;
  }

  .temporary-user-details-header-container {
    display: flex;
    width: 100%;
    justify-content: space-between;
    flex-grow: 0;
    gap: 16px;
    align-items: center;

    .close-button {
      width: 40px;
      height: 40px;
      margin-left: 8px;
      background-color: constants.$primary-container;

      svg {
        color: constants.$on-primary-container;
        height: 20px;
        width: 20px;
      }
    }
  }

  .viewing-mode-padding {
    padding-bottom: 64px;
  }

  .temporary-user-details-body-container {
    display: flex;
    padding-top: 16px;
    align-items: flex-start;
    gap: 24px;
    align-self: stretch;

    .temporary-user-details-form-container {
      flex: 0 1 calc(50% - 12px);
      display: flex;
      flex-direction: column;
      gap: 24px;
      width: fit-content;
      align-self: stretch;
      overflow: auto;

      .temporary-user-details-form-group {
        display: flex;
        flex-direction: column;
        gap: 16px;
      }
    }
  }
}
