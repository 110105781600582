.tree-view-container {
  overflow-y: auto;

  .starting-icons {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
  }

  .ending-icons {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
  }
}
