@use "style/constants.scss" as constants;

.category {
  border-radius: 8px;
  padding: 4px 8px;
  margin: 8px 0;
  max-width: fit-content;

  .categoryText {
    color: constants.$on-primary-container;
  }
}

.highestPriority {
  background-color: #ba1a1a;
  .categoryText {
    color: constants.$on-primary;
  }
}

.highPriority {
  background-color: #ffa014;
}

.mediumPriority {
  background-color: #f3d94e;
}

.defaultPriority {
  background-color: #f6f6f6;
}

.status {
  background-color: constants.$primary-container;
}

.bread-crumb-item {
  color: constants.$on-surface-variant;
}
