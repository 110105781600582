@use "style/constants.scss" as constants;

.deviceSettingsContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;

  .beaconSettingsContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    gap: 16px;

    .label {
      display: flex;
      gap: 16px;
    }

    .formField {
      width: 245px;
    }

    .proximityId {
      display: flex;
      gap: 8px;
    }
  }
}
