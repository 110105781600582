@use "style/constants.scss" as constants;

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  border: 1px solid constants.$outline-variant;
  border-radius: 8px;
  padding: 16px;

  .alarmActiveRow {
    margin-top: 16px;

    .alarmActiveSelect {
      width: 100%;
      height: 56px;
    }
  }

  .restoreRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}
