@use "style/constants.scss" as constants;

.page-header-container {
  position: sticky;
  top: 0;
  z-index: 100;
  display: flex;
  flex-direction: column;
  gap: 32px;

  .description {
    margin: 16px 0;
  }

  .page-header-and-tab-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 32px;
  }

  .add-button {
    z-index: initial;
    bottom: -28px;
    right: 40px;
  }
}
