@use "style/constants.scss" as constants;

.layout-container {
  display: flex;
  max-width: 100vw;
  height: 100vh;
  background-color: constants.$background;

  .menu-container {
    min-width: 256px;
  }

  .layout-body {
    display: flex;
    flex-direction: column;
    padding: 72px 64px 0px 64px;
    width: 100%;
    height: 100%;
    text-align: start;
    min-width: 0;

    -ms-overflow-style: none;
    scrollbar-width: none;
    overflow-x: hidden;
  }

  .layout-body::-webkit-scrollbar {
    display: none;
  }
}
