@use "style/constants.scss" as constants;

.temporary-access-filters-container {
  display: flex;
  gap: 32px;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;

  .temporary-access-filters {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    align-items: center;

    .clear-filters {
      display: flex;
      align-items: center;
      padding: 0 12px;
      cursor: pointer;
    }
  }

  .refresh-button svg {
    color: constants.$on-surface;
  }
}
