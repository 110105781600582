@use "style/constants.scss" as constants;

.login-container {
  background-color: #470691;
  height: 100vh;
  width: 100vw;

  .image-container {
    position: absolute;
    left: 0;
    bottom: 0;

    .logo {
      position: absolute;
      left: 16.5%;
      top: 44%;
    }
  }

  .login-form-container {
    display: flex;
    flex-direction: column;

    .login-title {
      margin-bottom: 16px;
    }

    .login-item {
      margin-bottom: 24px;
    }

    width: 295px;
    background-color: constants.$background;
    box-shadow: constants.$elevation-light-3;
    border-radius: 40px;
    padding: 40px;

    position: absolute;
    right: 160px;
    top: 50%;
    -webkit-transform: translateY(100%);
    transform: translateY(-50%);

    .login-button {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px 24px;

      width: 100%;
      height: 56px;
    }

    .MuiInputBase-root {
      border-radius: 32px;
    }
  }
}
