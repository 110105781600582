@use "style/constants.scss" as constants;

.organisation-picker {
  .organisation-picker-paper {
    height: 896px;
    width: 777px;
    min-width: 777px;
    padding-bottom: 0;
  }

  .organisation-picker-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 24px;

    :first-child {
      flex-grow: 1;
    }
  }

  .close-button {
    width: 40px;
    height: 40px;
    background-color: constants.$primary-container;

    svg {
      color: constants.$on-primary-container;
      height: 20px;
      width: 20px;
    }
  }
}
