@use "style/constants.scss" as constants;

.tenant-details-header-container {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  flex-grow: 0;
  gap: 16px;
  align-items: center;

  .close-button {
    width: 40px;
    height: 40px;
    margin-left: 8px;
    background-color: constants.$primary-container;

    svg {
      color: constants.$on-primary-container;
      height: 20px;
      width: 20px;
    }
  }
}
