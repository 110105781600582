@use "style/constants.scss" as constants;

.history-details-right-content-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  .title {
    padding: 0 16px 16px 8px;
    border-bottom: 1px solid rgba(123, 117, 127, 0.16);
  }

  .timeline-container {
    overflow-y: auto;
  }

  .top-box-shadow {
    box-shadow: 0px 4px 19px -10px rgba(0, 0, 0, 0.05) inset;
    border-bottom: 1px solid rgba(123, 117, 127, 0.16);
  }

  .bottom-box-shadow {
    box-shadow: 0px -4px 19px -10px rgba(0, 0, 0, 0.05) inset;
    border-bottom: 1px solid rgba(123, 117, 127, 0.16);
  }

  .top-and-bottom-box-shadow {
    box-shadow: 0px 4px 19px -10px rgba(0, 0, 0, 0.05) inset,
      0px -4px 19px -10px rgba(0, 0, 0, 0.05) inset;
    border-bottom: 1px solid rgba(123, 117, 127, 0.16);
  }

  .total-duration {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 16px;
  }
}
