@use "style/constants.scss" as constants;

.organisation-details-linkable-devices-modal {
  .linkable-devices-modal-paper {
    padding-bottom: 0;
    height: 960px;
    width: 960px;
    max-width: 960px;
  }

  .linkable-devices-modal-container {
    display: flex;
    flex-direction: column;
    gap: 24px;
    height: 100%;

    .linkable-devices-modal-header-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 8px;
      align-self: stretch;
      flex-grow: 0;

      .header-title {
        flex-grow: 1;
      }

      .linkable-devices-modal-header-actions-container {
        display: flex;
        flex-direction: row;
        gap: 40px;

        .link-button {
          .check-icon {
            font-size: 16px;
          }
        }
      }
    }
  }

  .close-button {
    width: 40px;
    height: 40px;
    background-color: constants.$primary-container;

    svg {
      color: constants.$on-primary-container;
      height: 20px;
      width: 20px;
    }
  }
}
