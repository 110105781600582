@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.sonevo-loader {
  &.white {
    .sonevo-loader-svg {
      fill: white;
    }
  }
}
