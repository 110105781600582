@use "style/constants.scss" as constants;

.history-details {
  display: flex;
  flex-direction: column;
  gap: 24px;

  .loading-indicator {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
  }

  .history-details-paper {
    padding-left: 22px;
    padding-bottom: 0;
    height: 960px;
    width: 1227px;
    max-width: 1227px;

    .history-details-content {
      display: flex;
      flex-direction: row;
      gap: 24px;
      margin-top: 24px;
      overflow-y: auto;

      .history-details-right-content {
        width: 100%;
      }
    }
  }
}
