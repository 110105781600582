.resident-filters {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 16px;

  .clear-filters {
    display: flex;
    align-items: center;
    padding: 0 12px;
    cursor: pointer;
  }
}
