@use "style/constants.scss" as constants;

.video-scenario-configuration-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  border: 1px solid constants.$outline-variant;
  border-radius: 8px;
  padding: 16px;

  .scenario-active-row {
    margin-top: 16px;

    .scenario-active-select {
      width: 100%;
      height: 56px;
    }
  }

  .privacy-modus-row {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-top: 16px;

    .privacy-modus-control {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
    }
  }

  .restore-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .restore-button {
      color: constants.$primary;
      cursor: pointer;
    }
  }
}
