.generalConfigurationContainer {
  .contentContainer {
    padding: 0 16px;
    display: flex;
    flex-direction: column;
    gap: 8px;

    .textFields {
      margin-bottom: 8px;
      display: flex;
      flex-direction: column;
      gap: 8px;

      .roomTextField * {
        word-break: break-word;
      }
    }

    .sliderIcon {
      margin-top: 4px;
    }

    .sliderIconSmall {
      margin-top: 8px;
      width: 14px;
      height: 14px;
    }
  }

  .divider {
    padding: 16px 0px;
  }
}
