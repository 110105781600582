@use "style/constants.scss" as constants;

.outlined-toggle-button {
  &.selected {
    color: constants.$on-surface;
    border-color: constants.$outline;
    background: constants.$surface-variant;
  }

  &.MuiButton-root {
    color: constants.$on-surface;
    border-color: constants.$outline;
  }

  &.MuiButton-root:hover {
    background: constants.$surface-variant;
    border-color: constants.$outline;
  }
}
