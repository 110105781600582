@use "style/constants.scss" as constants;

.summary {
  width: 100%;
  display: flex;
  justify-content: space-between;

  .group-container {
    display: flex;
    gap: 18px;
    align-items: center;

    .MuiSvgIcon-root {
      color: constants.$primary;
      width: 20px;
      height: 20px;
    }
  }
}
