@use "style/constants.scss" as constants;

.table-container {
  height: 100%;

  .more-icon-button {
    visibility: hidden;

    &.is-selected {
      visibility: visible;
    }

    .more-icon {
      height: 24px;
      width: 24px;
      color: constants.$on-background;
    }
  }

  tbody tr .table-cell {
    height: 40px;
  }

  .table-cell {
    padding: 0 8px;
  }

  .selection-checkbox {
    visibility: hidden;

    &.is-checked {
      visibility: visible;
    }
  }

  .table-row-highlighted:hover .more-icon-button,
  .table-row:hover .more-icon-button {
    visibility: visible;
  }

  .table-row-highlighted:hover .selection-checkbox,
  .table-row:hover .selection-checkbox {
    visibility: visible;
  }

  .no-hover-table-row:hover {
    background-color: unset;
  }

  .table-cell-highlighted {
    background: constants.$surface1;
    padding: 8px;
    border-bottom: 1px solid constants.$outline-variant;
    border-top: 1px solid constants.$outline-variant;
    &:first-child {
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
      border-left: 1px solid constants.$outline-variant;
    }
    &:last-child {
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
      border-right: 1px solid constants.$outline-variant;
    }
  }

  .table-row-highlighted {
    &:hover .MuiTableCell-root:first-of-type {
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
    }

    &:hover .MuiTableCell-root:last-of-type {
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
    }
  }
}
