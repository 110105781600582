@use "style/constants.scss" as constants;

.devices-container {
  height: 100%;
  display: flex;
  flex-direction: column;

  .icon-row {
    display: flex;
    flex-direction: row;
    gap: 16px;
  }
}
