@use "style/constants.scss" as constants;

.organisation-device-actions-row-container {
  display: flex;
  align-items: center;
  gap: 16px;
  margin-right: 16px;
  min-height: 40px;
}

.organisation-details-row-container-editing {
  justify-content: space-between;
}

.organisation-details-devices-table {
  .clickable-device {
    text-decoration: underline;
    text-underline-position: under;
  }

  .unlinked-device {
    text-decoration: line-through;
  }
}

.organisation-details-devices-table th {
  background-color: constants.$surface1;
}

.organisation-details-devices-table th:last-of-type {
  width: 185px;
  padding-left: 12px;
}

.organisation-details-devices-table td:last-of-type {
  justify-content: end;
}
