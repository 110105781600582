.container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 16px;

  .infoItem {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .secret {
    display: flex;
    align-items: center;
    gap: 16px;
  }
}

.secretButton {
  margin-top: 8px;
}

.clusterItem {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 40px;

  &:last-child {
    margin-bottom: 0;
  }
}

.textfield {
  max-width: 256px;
}
