@use "style/constants.scss" as constants;

.monitoring-alert-container {
  display: flex;
  flex-direction: column;
  gap: 16px;

  .status-container {
    padding: 16px;
    border-radius: 8px;

    .status-row {
      display: flex;
      flex-direction: row;
      gap: 16px;
      align-items: center;
    }

    .text-padding {
      padding-top: 8px;
    }
  }
}
