@use "style/constants.scss" as constants;

.settings-switch {
  .MuiSvgIcon-root {
    color: constants.$primary;
  }

  .MuiTypography-root {
    color: constants.$on-surface-variant;
  }

  .helper-text {
    color: constants.$error;
    margin-left: 14px;
  }
}
