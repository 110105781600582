@use "style/constants.scss" as constants;

.organisation-container {
  display: flex;
  height: 100%;
  overflow-y: hidden;
  gap: 32px;

  .organisation-structure-container {
    width: 100%;
    display: flex;
    flex-direction: column;

    &.detail-is-opened {
      width: 50%;
      min-width: 480px;
      max-width: 980px;
    }
  }

  .organisation-unit-detail-container {
    width: 0%;
    min-width: 544px;
    display: none;

    &.detail-is-opened {
      display: block;
      flex-grow: 1;
      animation: reveal 150ms forwards;
    }
  }
}
