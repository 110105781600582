.overflow-menu-paper {
  .MuiDivider-root {
    margin: 13px;
  }

  :first-child {
    margin-top: 0px;
  }
  :last-child {
    margin-bottom: 0px;
  }
}
